import DescriptiveList from 'components/DescriptiveList';
import SectionWrapper from 'components/SectionWrapper';
import TrackVisibilityWrapper from 'components/TrackVisibilityWrapper';
import React from 'react';
import { ContentSection, PageSectionType } from 'utils/types';

import * as S from './DescriptiveListContainer.styles';

export type PartialContentSection = Pick<
  ContentSection,
  'title' | 'description' | 'image'
>;

export type DescriptiveListContainerProps = Pick<
  PageSectionType,
  'sectionId' | 'title' | 'headline' | 'description' | 'cta'
> & {
  listOfContentSections?: PartialContentSection[];
  onCtaClick?: (linkId: string) => void;
};

/**
 * Container to map contentful page section props to DescriptiveList props.
 */
function DescriptiveListContainer(
  props: DescriptiveListContainerProps,
): JSX.Element {
  const {
    sectionId: label,
    title: slug,
    headline,
    description,
    cta,
    listOfContentSections = [],
    onCtaClick,
  } = props;

  const listItems = listOfContentSections.map(
    ({ title, description: { description }, image }) => ({
      title,
      description,
      image: image.file?.url,
    }),
  );

  return (
    <TrackVisibilityWrapper
      label={label}
      slug={slug || 'No title in Contentful - DescriptiveList'}
    >
      <SectionWrapper>
        <S.SectionContainer>
          <DescriptiveList
            headline={headline!}
            subheadline={description?.description}
            cta={cta}
            listItems={listItems}
            hasLargerCentralizedHeadline={true}
            onCtaClick={onCtaClick}
          />
        </S.SectionContainer>
      </SectionWrapper>
    </TrackVisibilityWrapper>
  );
}

export default DescriptiveListContainer;
