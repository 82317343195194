import { size, Container, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const SectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${mediaQueries.forTabletHorizontalUp} {
    align-items: center;
    text-align: center;
  }

  > div.subheadline {
    ${mediaQueries.forTabletHorizontalUp} {
      margin-bottom: ${size.xl2}px;
      padding: 0 ${size.sm}rem 0;
    }
  }
`;
